import { Route } from '@sixfold/app-framework';
import { generatePath as routerGeneratePath } from 'react-router-dom';

import {
  IntegrationRouteParameters,
  GoRedirectionRouteParameters,
  ShareRouteParameters,
  FleetMonitorView,
  VehicleManagementContentType,
  VehicleManagementSidePanel,
  InvitationType,
} from './routes_parameters';

export type Supported = { company_id: string };
export const Supported: Route<Supported> = {
  routerPath:
    '/companies/:company_id/(carriers|network|warehouses|transports|shipments|dashboards|users|settings|onboarding|platform-home|help|vehicles|fleet|latest-map-report|support-ticket|feedback|landing-page)?/:subpath',
  generatePath: (params) => routerGeneratePath(Supported.routerPath, params),
};

export type Unsupported = { company_id: string; unsupported_view: string };
export const Unsupported: Route<Unsupported> = {
  routerPath: '/companies/:company_id/:unsupported_view',
  generatePath: (params) => routerGeneratePath(Unsupported.routerPath, params),
};

export type Consignee = { token: string; resourceType: ShareRouteParameters };
export const Consignee: Route<Consignee> = {
  routerPath: `/share/:resourceType(${[ShareRouteParameters.STOP, ShareRouteParameters.DELIVERY].join('|')})/:token`,
  generatePath: (params) => routerGeneratePath(Consignee.routerPath, params),
  tracking: {
    name: 'Share UI',
    category: 'Consignee',
  },
};

export type SharedTour = { token: string };
export const SharedTour: Route<SharedTour> = {
  routerPath: `/share/${ShareRouteParameters.TOUR}/:token`,
  generatePath: (params) => routerGeneratePath(SharedTour.routerPath, params),
  tracking: {
    name: 'Share UI',
    category: 'Shared tour',
  },
};

export type CustomerPortal = { token: string };
export const CustomerPortal: Route<CustomerPortal> = {
  routerPath: `/share/${ShareRouteParameters.TRANSPORTS}/:token`,
  generatePath: (params) => routerGeneratePath(CustomerPortal.routerPath, params),
  tracking: {
    name: 'Share UI',
    category: 'Customer Portal',
  },
};

/**
 * @deprecated Use CustomerPortal instead
 */
export type LegacyCustomerPortal = { token: string };
/**
 * @deprecated Use CustomerPortal instead
 */
export const LegacyCustomerPortal: Route<LegacyCustomerPortal> = {
  routerPath: `/share/${ShareRouteParameters.LEGACY_SHIPMENTS}/:token`,
  generatePath: (params) => routerGeneratePath(LegacyCustomerPortal.routerPath, params),
  tracking: {
    name: 'Share UI',
    category: 'Customer Portal (Legacy Link)',
  },
};

export type CustomerPortalTransport = { token: string; tour_id: string };
export const CustomerPortalTransport: Route<CustomerPortalTransport> = {
  routerPath: `/share/${ShareRouteParameters.TRANSPORTS}/:token/${ShareRouteParameters.TRANSPORTS}/:tour_id`,
  generatePath: (params) => routerGeneratePath(CustomerPortalTransport.routerPath, params),
  tracking: {
    name: 'Share UI',
    category: 'Customer Portal Transport',
  },
};

export type GoRedirect = { redirect: GoRedirectionRouteParameters; value?: string };
export const GoRedirect: Route<GoRedirect> = {
  routerPath: `/go/:redirect(${Object.values(GoRedirectionRouteParameters).join('|')})/:value?`,
  generatePath: (params) => routerGeneratePath(GoRedirect.routerPath, params),
  tracking: {
    name: 'Current company redirect',
  },
};

export type Go = { token: string };
export const Go: Route<Go> = {
  routerPath: '/go/:token',
  generatePath: (params) => routerGeneratePath(Go.routerPath, params),
};

export type Company = { company_id: string };
export const Company: Route<Company> = {
  routerPath: '/companies/:company_id',
  generatePath: (params) => routerGeneratePath(Company.routerPath, params),
  tracking: {
    name: 'Company',
  },
};

export type PlatformHome = { token: string };
export const PlatformHome: Route<PlatformHome> = {
  routerPath: '/companies/:company_id/platform-home',
  generatePath: (params) => routerGeneratePath(PlatformHome.routerPath, params),
  tracking: {
    name: 'Platform Home',
    category: 'Platform home redirect',
  },
};

/**
 * @deprecated Use TransportFullscreen instead
 */
export type Transport = { company_id: string; tour_id: string };
/**
 * @deprecated Use TransportFullscreen instead
 */
export const Transport: Route<Transport> = {
  routerPath: '/companies/:company_id/transports/:tour_id',
  generatePath: (params) => routerGeneratePath(Transport.routerPath, params),
  tracking: {
    name: 'Transports (opened)',
  },
};

export type TransportFullscreen = { company_id: string; tour_id: string };
export const TransportFullscreen: Route<TransportFullscreen> = {
  routerPath: '/companies/:company_id/transports/:tour_id/fullscreen',
  generatePath: (params) => routerGeneratePath(TransportFullscreen.routerPath, params),
  tracking: {
    name: 'Transport (fullscreen)',
  },
};

export type Transports = { company_id: string; tour_id?: string };
export const Transports: Route<Transports> = {
  routerPath: '/companies/:company_id/transports/:tour_id?',
  generatePath: (params) => routerGeneratePath(Transports.routerPath, params),
  tracking: {
    name: 'Transports',
  },
};

export type TripsTourTransports = { company_id: string };
export const OceanTransports: Route<TripsTourTransports> = {
  routerPath: '/companies/:company_id/ocean_transports',
  generatePath: (params) => routerGeneratePath(OceanTransports.routerPath, params),
  tracking: {
    name: 'Ocean Transports',
  },
};

export type TripsTransportDetails = TripsTourTransports & { tour_id: string };
export const OceanTransportDetails: Route<TripsTransportDetails> = {
  routerPath: `${OceanTransports.routerPath}/:tour_id`,
  generatePath: (params) => routerGeneratePath(OceanTransportDetails.routerPath, params),
  tracking: {
    name: 'Ocean transport details',
  },
};

export const AirTransports: Route<TripsTourTransports> = {
  routerPath: '/companies/:company_id/air_transports',
  generatePath: (params) => routerGeneratePath(AirTransports.routerPath, params),
  tracking: {
    name: 'Air Transports',
  },
};
export const AirTransportDetails: Route<TripsTransportDetails> = {
  routerPath: `${AirTransports.routerPath}/:tour_id`,
  generatePath: (params) => routerGeneratePath(AirTransportDetails.routerPath, params),
  tracking: {
    name: 'Air transport details',
  },
};

export type Places = { company_id: string; place_id?: string };
export const Places: Route<Places> = {
  routerPath: '/companies/:company_id/places/:place_id?',
  generatePath: (params) => routerGeneratePath(Places.routerPath, params),
  tracking: { name: 'Places' },
};

export type PlacesEdit = { company_id: string; place_id: string };
export const PlacesEdit: Route<PlacesEdit> = {
  routerPath: '/companies/:company_id/places/:place_id/edit',
  generatePath: (params) => routerGeneratePath(PlacesEdit.routerPath, params),
  tracking: { name: 'Places edit' },
};

export type PlacesCreate = { company_id: string; address_id?: string };
export const PlacesCreate: Route<PlacesCreate> = {
  routerPath: '/companies/:company_id/places/new/:address_id?',
  generatePath: (params) => routerGeneratePath(PlacesCreate.routerPath, params),
  tracking: { name: 'Places creation' },
};

export type PlaceTransport = { company_id: string; place_id: string; tour_id: string };
export const PlaceTransport: Route<PlaceTransport> = {
  routerPath: '/companies/:company_id/places/:place_id/transports/:tour_id',
  generatePath: (params) =>
    routerGeneratePath(PlaceTransport.routerPath, {
      company_id: params.company_id,
      place_id: params.place_id,
      tour_id: params.tour_id,
    }),
  tracking: {
    name: 'Place Transport',
  },
};

export type Onboarding = {
  company_id: string;
};
export const Onboarding: Route<Onboarding> = {
  routerPath: '/companies/:company_id/onboarding',
  generatePath: (params) => routerGeneratePath(Onboarding.routerPath, params),
  tracking: {
    name: 'Onboarding',
  },
};

export type TransportNetwork = { company_id: string; report_type?: string };
export const TransportNetwork: Route<TransportNetwork> = {
  routerPath: '/companies/:company_id/network/:report_type?',
  generatePath: (params) => routerGeneratePath(TransportNetwork.routerPath, params),
  tracking: {
    name: 'Transport network',
  },
};

export type TransportNetworkOnboardingProgress = { company_id: string; report_type: string; partner_id?: string };
export const TransportNetworkOnboardingProgress: Route<TransportNetworkOnboardingProgress> = {
  routerPath: '/companies/:company_id/network/:report_type/onboarding/:partner_id?',
  generatePath: (params) => routerGeneratePath(TransportNetworkOnboardingProgress.routerPath, params),
  tracking: {
    name: 'Transport network - Onboarding',
  },
};

export type TransportNetworkPerformanceReport = { company_id: string; report_type: string; partner_id?: string };
export const TransportNetworkPerformanceReport: Route<TransportNetworkPerformanceReport> = {
  routerPath: '/companies/:company_id/network/:report_type/performance/:partner_id?',
  generatePath: (params) => routerGeneratePath(TransportNetworkPerformanceReport.routerPath, params),
  tracking: {
    name: 'Transport network - Visibility',
  },
};

export type InviteTransportNetworkPartners = { company_id: string };
export const InviteTransportNetworkPartners: Route<InviteTransportNetworkPartners> = {
  routerPath: '/companies/:company_id/network/invite',
  generatePath: (params) => routerGeneratePath(InviteTransportNetworkPartners.routerPath, params),
  tracking: {
    name: 'Transport network – Partners invite',
  },
};

export type DataNetworkBulkInviteTransportNetworkPartners = {
  company_id: string;
  bulk_invitation_set_id?: string;
  sidepanel_type?: 'company' | 'invitation' | 'bulk-invitation-item';
  sidepanel_type_id?: string;
};
export const DataNetworkBulkInviteTransportNetworkPartners: Route<DataNetworkBulkInviteTransportNetworkPartners> = {
  routerPath:
    '/companies/:company_id/data-network/bulk-invite/:bulk_invitation_set_id?/:sidepanel_type(invitation|company|bulk-invitation-item)?/:sidepanel_type_id?',
  generatePath: (params) => routerGeneratePath(DataNetworkBulkInviteTransportNetworkPartners.routerPath, params),
  tracking: {
    name: 'Transport network - Partners bulk invite',
  },
};

export type DataNetwork = { company_id: string; report_type?: string };
export const DataNetwork: Route<DataNetwork> = {
  routerPath: '/companies/:company_id/data-network/:report_type?',
  generatePath: (params) => routerGeneratePath(DataNetwork.routerPath, params),
  tracking: {
    name: 'Data sharing network',
  },
};

export type DataNetworkProvidingVisibility = { company_id: string; partner_id?: string };
export const DataNetworkProvidingVisibility: Route<DataNetworkProvidingVisibility> = {
  routerPath: '/companies/:company_id/data-network/providing-visibility/consent/:partner_id?',
  generatePath: (params) => routerGeneratePath(DataNetworkProvidingVisibility.routerPath, params),
  tracking: {
    name: 'Data sharing network - Providing visibility',
  },
};

export type DataNetworkReceivingVisibility = {
  company_id: string;
  sidepanel_type?: 'company' | 'invitation';
  sidepanel_type_id?: string;
};
export const DataNetworkReceivingVisibility: Route<DataNetworkReceivingVisibility> = {
  routerPath:
    '/companies/:company_id/data-network/receiving-visibility/consent/:sidepanel_type(invitation|company)?/:sidepanel_type_id?',
  generatePath: (params) => routerGeneratePath(DataNetworkReceivingVisibility.routerPath, params),
  tracking: {
    name: 'Data sharing network - Receiving visibility',
  },
};

export type DataNetworkPerformanceReport = { company_id: string; report_type: string; partner_id?: string };
export const DataNetworkPerformanceReport: Route<DataNetworkPerformanceReport> = {
  routerPath: '/companies/:company_id/data-network/:report_type/performance/:partner_id?',
  generatePath: (params) => routerGeneratePath(DataNetworkPerformanceReport.routerPath, params),
  tracking: {
    name: 'Data sharing network - Visibility',
  },
};

export type LicensePlateLookup = { company_id: string };
export const LicensePlateLookup: Route<LicensePlateLookup> = {
  routerPath: '/companies/:company_id/data-network/license-plate-lookup',
  generatePath: (params) => routerGeneratePath(LicensePlateLookup.routerPath, params),
  tracking: {
    name: 'License plate lookup',
  },
};

export type DataNetworkInvitePartners = { company_id: string; type?: InvitationType };
export const DataNetworkInvitePartners: Route<DataNetworkInvitePartners> = {
  routerPath: '/companies/:company_id/data-network/invite/:type?',
  generatePath: (params) => routerGeneratePath(DataNetworkInvitePartners.routerPath, params),
  tracking: {
    name: 'Data sharing network – Partners invite',
  },
};

/**
 * @deprecated Use Settings instead, this acts as redirect to it
 */
export type LegacyUsers = { company_id: string };
/**
 * @deprecated Use Settings instead, this acts as redirect to it
 */
export const LegacyUsers: Route<LegacyUsers> = {
  routerPath: '/companies/:company_id/users',
  generatePath: (params) => routerGeneratePath(LegacyUsers.routerPath, params),
  tracking: {
    name: 'Users',
  },
};

export type Settings = { company_id: string };
export const Settings: Route<Settings> = {
  routerPath: '/companies/:company_id/settings',
  generatePath: (params) => routerGeneratePath(Settings.routerPath, params),
  tracking: {
    name: 'Settings',
  },
};

export type Dashboard = { company_id: string };
export const Dashboard: Route<Dashboard> = {
  routerPath: '/companies/:company_id/dashboard',
  generatePath: (params) => routerGeneratePath(Dashboard.routerPath, params),
  tracking: {
    name: 'Old Dashboard',
  },
};

export type Search = { company_id: string };
export const Search: Route<Search> = {
  routerPath: '/companies/:company_id/search',
  generatePath: (params) => routerGeneratePath(Search.routerPath, params),
  tracking: {
    name: 'Search',
  },
};

export type Integrations = {
  company_id: string;
};
export const Integrations: Route<NewIntegration> = {
  routerPath: '/companies/:company_id/settings/integrations/',
  generatePath: (params) => routerGeneratePath(Integrations.routerPath, params),
  tracking: {
    name: 'Integration selection',
  },
};

export type CompanyProfileSettings = {
  company_id: string;
};
export const CompanyProfileSettings: Route<CompanyProfileSettings> = {
  routerPath: '/companies/:company_id/settings/profile/',
  generatePath: (params) => routerGeneratePath(CompanyProfileSettings.routerPath, params),
  tracking: {
    name: 'Settings > company profile',
  },
};

export type CompanyVisibilitySettings = {
  company_id: string;
};
export const CompanyVisibilitySettings: Route<CompanyVisibilitySettings> = {
  routerPath: '/companies/:company_id/settings/visibility/',
  generatePath: (params) => routerGeneratePath(CompanyVisibilitySettings.routerPath, params),
  tracking: {
    name: 'Settings > company visibility settings',
  },
};

export type UserSettings = {
  company_id: string;
};
export const UserSettings: Route<UserSettings> = {
  routerPath: '/companies/:company_id/settings/users/',
  generatePath: (params) => routerGeneratePath(UserSettings.routerPath, params),
  tracking: {
    name: 'Settings > users',
  },
};

export type NotificationSettings = {
  company_id: string;
};
export const NotificationSettings: Route<NotificationSettings> = {
  routerPath: '/companies/:company_id/settings/notification-preferences/',
  generatePath: (params) => routerGeneratePath(NotificationSettings.routerPath, params),
  tracking: {
    name: 'Settings > notification preferences',
  },
};

export type IntegrationSettings = {
  company_id: string;
};
export const IntegrationSettings: Route<IntegrationSettings> = {
  routerPath: '/companies/:company_id/settings/integrations/overview/',
  generatePath: (params) => routerGeneratePath(IntegrationSettings.routerPath, params),
  tracking: {
    name: 'Settings > integrations',
  },
};

export type NewIntegration = {
  company_id: string;
  provider_id?: string | IntegrationRouteParameters;
};
export const NewIntegration: Route<NewIntegration> = {
  routerPath: '/companies/:company_id/settings/integrations/new/:provider_id?',
  generatePath: (params) => routerGeneratePath(NewIntegration.routerPath, params),
  tracking: {
    name: 'Integration selection',
  },
};

export type ConfigureExistingIntegration = {
  company_id: string;
  telematics_integration_id: string;
};
export const ConfigureExistingIntegration: Route<ConfigureExistingIntegration> = {
  routerPath: '/companies/:company_id/settings/integrations/configure/:telematics_integration_id',
  generatePath: (params) => routerGeneratePath(ConfigureExistingIntegration.routerPath, params),
  tracking: {
    name: 'Integration configuration',
  },
};

export type Fleet = {
  company_id: string;
  vehicle_group_id?: string;
  view?: FleetMonitorView;
};

export const Fleet: Route<Fleet> = {
  routerPath: '/companies/:company_id/fleet/:vehicle_group_id?/:view?',
  generatePath: (params) => routerGeneratePath(Fleet.routerPath, params),
  tracking: {
    name: 'Fleet',
  },
};

export type FleetAll = {
  company_id: string;
  view?: FleetMonitorView;
};

export const FleetAll: Route<FleetAll> = {
  routerPath: '/companies/:company_id/fleet/all/:view?',
  generatePath: (params) => routerGeneratePath(FleetAll.routerPath, params),
  tracking: {
    name: 'Fleet',
  },
};

export type Dashboards = {
  company_id: string;
};

export const Dashboards: Route<Dashboards> = {
  routerPath: '/companies/:company_id/dashboards/',
  generatePath: (params) => routerGeneratePath(Dashboards.routerPath, params),
  tracking: {
    name: 'Dashboards',
  },
};

export type DashboardById = {
  company_id: string;
  dashboard_id: string;
};

export const DashboardById: Route<DashboardById> = {
  routerPath: '/companies/:company_id/dashboards/:dashboard_id',
  generatePath: (params) => routerGeneratePath(DashboardById.routerPath, params),
  tracking: {
    name: 'Dashboard',
  },
};

export type NotificationsOperations = {
  company_id: string;
  notification_id?: string;
};

export const NotificationsOperations: Route<NotificationsOperations> = {
  routerPath: '/companies/:company_id/notifications/operations/:notification_id?',
  generatePath: (params) => routerGeneratePath(NotificationsOperations.routerPath, params),
  tracking: {
    name: 'Notifications operations',
  },
};

export type Notifications = {
  company_id: string;
};

export const Notifications: Route<Notifications> = {
  routerPath: '/companies/:company_id/notifications',
  generatePath: (params) => routerGeneratePath(Notifications.routerPath, params),
  tracking: {
    name: 'Notifications',
  },
};

export type FleetEdit = {
  company_id: string;
  vehicle_group_id: string;
  view: FleetMonitorView;
};
export const FleetEdit: Route<FleetEdit> = {
  routerPath: '/companies/:company_id/fleet/:vehicle_group_id/:view/edit',
  generatePath: (params) => routerGeneratePath(FleetEdit.routerPath, params),
  tracking: {
    name: 'Fleet editing',
  },
};

export type FleetCreate = {
  company_id: string;
  vehicle_group_id: string;
  view: FleetMonitorView;
};

export const FleetCreate: Route<FleetCreate> = {
  routerPath: '/companies/:company_id/fleet/:vehicle_group_id/:view/create',
  generatePath: (params) => routerGeneratePath(FleetCreate.routerPath, params),
  tracking: {
    name: 'Fleet vehicle group create',
  },
};

export type FleetVehicleDetails = {
  company_id: string;
  vehicle_group_id: string;
  vehicle_id: string;
  view: FleetMonitorView;
};
export const FleetVehicleDetails: Route<FleetVehicleDetails> = {
  routerPath: '/companies/:company_id/fleet/:vehicle_group_id/:view/vehicle/:vehicle_id',
  generatePath: (params) => routerGeneratePath(FleetVehicleDetails.routerPath, params),
  tracking: {
    name: 'Fleet vehicle details',
  },
};

export type FleetVehicleAllocation = {
  company_id: string;
  vehicle_group_id: string;
  vehicle_id: string;
  view: FleetMonitorView;
};
export const FleetVehicleAllocation: Route<FleetVehicleAllocation> = {
  routerPath: '/companies/:company_id/fleet/:vehicle_group_id/:view/vehicle/:vehicle_id/allocation',
  generatePath: (params) => routerGeneratePath(FleetVehicleAllocation.routerPath, params),
  tracking: {
    name: 'Fleet vehicle allocation',
  },
};

export type FleetCompanyUnallocatedShipments = {
  company_id: string;
  vehicle_group_id: string;
  view: FleetMonitorView;
};
export const FleetCompanyUnallocatedShipments: Route<FleetCompanyUnallocatedShipments> = {
  routerPath: '/companies/:company_id/fleet/:vehicle_group_id/:view/company-unallocated-shipments',
  generatePath: (params) => routerGeneratePath(FleetCompanyUnallocatedShipments.routerPath, params),
  tracking: {
    name: 'Fleet company unallocated shipments',
  },
};

export type FleetTransportDetails = {
  company_id: string;
  vehicle_group_id: string;
  transport_id: string;
  view: FleetMonitorView;
};
export const FleetTransportDetails: Route<FleetTransportDetails> = {
  routerPath: '/companies/:company_id/fleet/:vehicle_group_id/:view/transport/:transport_id',
  generatePath: (params) => routerGeneratePath(FleetTransportDetails.routerPath, params),
  tracking: {
    name: 'Fleet transport details',
  },
};

export type FleetTransportCreate = {
  company_id: string;
  vehicle_group_id: string;
  view: FleetMonitorView;
};
export const FleetTransportCreate: Route<FleetTransportCreate> = {
  routerPath: '/companies/:company_id/fleet/:vehicle_group_id/:view/transport/create',
  generatePath: (params) => routerGeneratePath(FleetTransportCreate.routerPath, params),
  tracking: {
    name: 'Fleet transport creation',
  },
};

export type FleetTransportVehicleCreate = {
  company_id: string;
  vehicle_group_id: string;
  vehicle_id: string;
  view: FleetMonitorView;
};
export const FleetTransportVehicleCreate: Route<FleetTransportVehicleCreate> = {
  routerPath: '/companies/:company_id/fleet/:vehicle_group_id/:view/vehicle/:vehicle_id/transport/create',
  generatePath: (params) => routerGeneratePath(FleetTransportVehicleCreate.routerPath, params),
  tracking: {
    name: 'Fleet transport creation',
  },
};

export type FleetTransportEdit = {
  company_id: string;
  vehicle_group_id: string;
  transport_id: string;
  view: FleetMonitorView;
};
export const FleetTransportEdit: Route<FleetTransportEdit> = {
  routerPath: '/companies/:company_id/fleet/:vehicle_group_id/:view/transport/:transport_id/edit',
  generatePath: (params) => routerGeneratePath(FleetTransportEdit.routerPath, params),
  tracking: {
    name: 'Fleet transport edit',
  },
};

export type FleetTransportAllocation = {
  company_id: string;
  vehicle_group_id: string;
  transport_id: string;
  vehicle_id: string;
  view: FleetMonitorView;
};
export const FleetTransportAllocation: Route<FleetTransportAllocation> = {
  routerPath:
    '/companies/:company_id/fleet/:vehicle_group_id/:view/vehicle/:vehicle_id/transport/:transport_id/allocation',
  generatePath: (params) => routerGeneratePath(FleetTransportAllocation.routerPath, params),
  tracking: {
    name: 'Fleet transport vehicle allocation',
  },
};

export type FleetTransport = {
  company_id: string;
  view: FleetMonitorView;
  vehicle_group_id?: string;
  tour_id: string;
};

export const FleetTransport: Route<FleetTransport> = {
  routerPath: '/companies/:company_id/fleet/:vehicle_group_id?/:view/transports/:tour_id',
  generatePath: (params) =>
    routerGeneratePath(FleetTransport.routerPath, {
      company_id: params.company_id,
      view: params.view,
      vehicle_group_id: params.vehicle_group_id,
      tour_id: params.tour_id,
    }),
  tracking: {
    name: 'Fleet Transport',
  },
};

export type VehicleManagement = {
  company_id: string;
};

export const VehicleManagement: Route<VehicleManagement> = {
  routerPath: '/companies/:company_id/vehicle-management/',
  generatePath: (params) => routerGeneratePath(VehicleManagement.routerPath, params),
  tracking: {
    name: 'Vehicle Management',
  },
};

export type VehicleManagementDedicatedVehiclesNoSidePanel = {
  company_id: string;
  content_type: VehicleManagementContentType;
  side_panel_card: undefined;
};

export type VehicleManagementDedicatedVehiclesTableWithSidePanel = {
  company_id: string;
  content_type: VehicleManagementContentType.DETAILS;
  side_panel_card: VehicleManagementSidePanel.VEHICLE_DETAILS;
  side_panel_id: string;
};

export type VehicleManagementDedicatedVehicles =
  | VehicleManagementDedicatedVehiclesNoSidePanel
  | VehicleManagementDedicatedVehiclesTableWithSidePanel;

export const VehicleManagementDedicatedVehicles: Route<VehicleManagementDedicatedVehicles> = {
  routerPath:
    '/companies/:company_id/vehicle-management/dedicated-vehicles/:content_type/:side_panel_card?/:side_panel_id?',
  generatePath: (params) => routerGeneratePath(VehicleManagementDedicatedVehicles.routerPath, params),
  tracking: {
    name: 'Vehicle Management > Dedicated vehicles',
  },
};

export type VehicleManagementDedicatedByPartners = {
  company_id: string;
};

export const VehicleManagementDedicatedByPartners: Route<VehicleManagementDedicatedByPartners> = {
  routerPath: '/companies/:company_id/vehicle-management/dedicated-by-partners',
  generatePath: (params) => routerGeneratePath(VehicleManagementDedicatedByPartners.routerPath, params),
  tracking: {
    name: 'Vehicle Management > Dedicated by partners',
  },
};

export type VehicleManagementDedicatedByPartnerNoSidePanel = {
  company_id: string;
  partner_company_id: string;
  content_type: VehicleManagementContentType;
  side_panel_card: undefined;
};

export type VehicleManagementDedicatedByPartnerTableWithSidePanel = {
  company_id: string;
  partner_company_id: string;
  content_type: VehicleManagementContentType.DETAILS;
  side_panel_card: VehicleManagementSidePanel.VEHICLE_DETAILS;
  side_panel_id: string;
};

/**
 * @deprecated Use VehicleManagementDedicatedByPartner instead.
 */
export const VehicleManagementDedicatedByPartnerOld: Route<VehicleManagementDedicatedByPartner> = {
  routerPath:
    '/companies/:company_id/vehicle-management/dedicated-by-partner/:partner_company_id/:content_type/:side_panel_card?/:side_panel_id?',
  generatePath: (params) => routerGeneratePath(VehicleManagementDedicatedByPartner.routerPath, params),
  tracking: {
    name: 'Vehicle Management > Dedicated by partner',
  },
};

export type VehicleManagementDedicatedByPartner =
  | VehicleManagementDedicatedByPartnerNoSidePanel
  | VehicleManagementDedicatedByPartnerTableWithSidePanel;

export const VehicleManagementDedicatedByPartner: Route<VehicleManagementDedicatedByPartner> = {
  routerPath:
    '/companies/:company_id/vehicle-management/dedicated-by-partners/:partner_company_id/:content_type/:side_panel_card?/:side_panel_id?',
  generatePath: (params) => routerGeneratePath(VehicleManagementDedicatedByPartner.routerPath, params),
  tracking: {
    name: 'Vehicle Management > Dedicated by partner',
  },
};

export type VehicleManagementDedicatedToPartners = {
  company_id: string;
};

export const VehicleManagementDedicatedToPartners: Route<VehicleManagementDedicatedToPartners> = {
  routerPath: '/companies/:company_id/vehicle-management/dedicated-to-partners',
  generatePath: (params) => routerGeneratePath(VehicleManagementDedicatedToPartners.routerPath, params),
  tracking: {
    name: 'Vehicle Management > Dedicated to partners',
  },
};

export type VehicleManagementDedicatedToPartnerNoSidePanel = {
  company_id: string;
  partner_company_id: string;
  content_type: VehicleManagementContentType;
  side_panel_card: undefined;
};

export type VehicleManagementDedicatedToPartnerTableWithSidePanel = {
  company_id: string;
  partner_company_id: string;
  content_type: VehicleManagementContentType.DETAILS;
  side_panel_card: VehicleManagementSidePanel.VEHICLE_DETAILS;
  side_panel_id: string;
};

/**
 * @deprecated Use VehicleManagementDedicatedToPartner instead.
 */
export const VehicleManagementDedicatedToPartnerOld: Route<VehicleManagementDedicatedToPartner> = {
  routerPath:
    '/companies/:company_id/vehicle-management/dedicated-to-partner/:partner_company_id/:content_type/:side_panel_card?/:side_panel_id?',
  generatePath: (params) => routerGeneratePath(VehicleManagementDedicatedToPartner.routerPath, params),
  tracking: {
    name: 'Vehicle Management > Dedicated to partner',
  },
};

export type VehicleManagementDedicatedToPartner =
  | VehicleManagementDedicatedToPartnerNoSidePanel
  | VehicleManagementDedicatedToPartnerTableWithSidePanel;

export const VehicleManagementDedicatedToPartner: Route<VehicleManagementDedicatedToPartner> = {
  routerPath:
    '/companies/:company_id/vehicle-management/dedicated-to-partners/:partner_company_id/:content_type/:side_panel_card?/:side_panel_id?',
  generatePath: (params) => routerGeneratePath(VehicleManagementDedicatedToPartner.routerPath, params),
  tracking: {
    name: 'Vehicle Management > Dedicated to partner',
  },
};

export type VehicleManagementIntegrations = {
  company_id: string;
};

export const VehicleManagementIntegrations: Route<VehicleManagementIntegrations> = {
  routerPath: '/companies/:company_id/vehicle-management/integrations',
  generatePath: (params) => routerGeneratePath(VehicleManagementIntegrations.routerPath, params),
  tracking: {
    name: 'Vehicle Management > Integrations',
  },
};

export type VehicleManagementIntegrationVehicles = {
  company_id: string;
  integration_id: string;
};

export const VehicleManagementIntegrationVehicles: Route<VehicleManagementIntegrationVehicles> = {
  routerPath: '/companies/:company_id/vehicle-management/integrations/:integration_id',
  generatePath: (params) => routerGeneratePath(VehicleManagementIntegrationVehicles.routerPath, params),
  tracking: {
    name: 'Vehicle Management > Integration vebicles',
  },
} as const;

/**
 * @deprecated Use VehicleManagement instead
 */
export type Vehicles = {
  company_id: string;
};
/**
 * @deprecated Use VehicleManagement instead
 */
export const Vehicles: Route<Vehicles> = {
  routerPath: '/companies/:company_id/vehicles',
  generatePath: (params) => routerGeneratePath(Vehicles.routerPath, params),
  tracking: {
    name: 'Vehicle Management',
  },
};

/**
 * @deprecated Use Places instead
 */
export type Warehouses = { company_id: string; place_id?: string };
/**
 * @deprecated Use Places instead
 */
export const Warehouses: Route<Warehouses> = {
  routerPath: '/companies/:company_id/warehouses/:place_id?',
  generatePath: (params) => routerGeneratePath(Warehouses.routerPath, params),
  tracking: {
    name: 'Warehouses',
  },
};

/**
 * @deprecated Use PlacesTransport instead
 */
export type WarehouseTransport = { company_id: string; place_id: string; tour_id: string };
/**
 * @deprecated Use PlacesTransport instead
 */
export const WarehouseTransport: Route<WarehouseTransport> = {
  routerPath: '/companies/:company_id/warehouses/:place_id/transports/:tour_id',
  generatePath: (params) =>
    routerGeneratePath(WarehouseTransport.routerPath, {
      company_id: params.company_id,
      place_id: params.place_id,
      tour_id: params.tour_id,
    }),
  tracking: {
    name: 'Warehouse Transport',
  },
};

/**
 * @deprecated Use TransportNetwork instead
 */
export type Carriers = { company_id: string };
/**
 * @deprecated Use TransportNetwork instead
 */
export const Carriers: Route<Carriers> = {
  routerPath: '/companies/:company_id/carriers/report',
  generatePath: (params) => routerGeneratePath(Carriers.routerPath, params),
  tracking: {
    name: 'Carriers',
  },
};

/**
 * @deprecated Use TransportNetwork instead
 */
export type Carrier = { company_id: string; carrier_id: string };
/**
 * @deprecated Use TransportNetwork instead
 */
export const Carrier: Route<Carrier> = {
  routerPath: '/companies/:company_id/carriers/report/:carrier_id',
  generatePath: (params) => routerGeneratePath(Carrier.routerPath, params),
  tracking: {
    name: 'Carrier report',
  },
};

export type CreateCustomerSupportTicket = { company_id: string };
export const CreateCustomerSupportTicket: Route<CreateCustomerSupportTicket> = {
  routerPath: '/companies/:company_id/support-ticket',
  generatePath: (params) => routerGeneratePath(CreateCustomerSupportTicket.routerPath, params),
  tracking: {
    name: 'Customer Support Ticket',
  },
};

export type CreateCustomerFeedback = { company_id: string };
export const CreateCustomerFeedback: Route<CreateCustomerFeedback> = {
  routerPath: '/companies/:company_id/feedback',
  generatePath: (params) => routerGeneratePath(CreateCustomerFeedback.routerPath, params),
  tracking: {
    name: 'Customer Feedback',
  },
};

export type HelpRedirect = { company_id: string };
export const HelpRedirect: Route<HelpRedirect> = {
  routerPath: '/companies/:company_id/help',
  generatePath: (params) => routerGeneratePath(HelpRedirect.routerPath, params),
  tracking: {
    name: 'Help redirect',
  },
};

export type LandingPage = { company_id: string };
export const LandingPage: Route<LandingPage> = {
  routerPath: '/companies/:company_id/landing-page',
  generatePath: (params) => routerGeneratePath(LandingPage.routerPath, params),
  tracking: {
    name: 'Landing Page',
  },
};
