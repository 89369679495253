import { atom } from 'recoil';

interface UserInCompanyConfiguration {
  vehicleDwellTimeThresholdMinutes: number | undefined;
  isUserSessionInvalidationOnUiInactivityEnabled: boolean;
}

export const userInCompanyConfigurationState = atom<UserInCompanyConfiguration | undefined>({
  key: 'userInCompanyConfigurationState',
  default: undefined,
});

export const companyDefaultDwellTimeThresholdState = atom<number | undefined>({
  key: 'companyDefaultDwellTimeThresholdState',
  default: undefined,
});
