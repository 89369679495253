import { useAnalytics, AnalyticsProvider as LibraryAnalyticsProvider } from '@sixfold/app-analytics-framework';
import { useLanguage } from '@sixfold/localization-component';
import { notNil } from '@sixfold/typed-primitives';
import * as React from 'react';

import { Company, useIsPresentOnTransporeonPlatform } from '../../company';
import { Routes } from '../../routes';
import { useUserProfile } from '../../user';
import { EmbedDataContext } from '../embed_data_context';

interface Props {
  company: Company;
}

export const AnalyticsCommonPropsReporter: React.FunctionComponent<Props> = ({
  company: { company_id: companyId, is_carrier: isCarrier, is_shipper: isShipper, is_test: isTest },
}) => {
  const language = useLanguage();
  const analytics = useAnalytics();
  const user = useUserProfile(companyId);

  const userId = user?.user_id ?? 'N/A';
  const role = user?.company?.role ?? 'N/A';

  React.useEffect(() => {
    analytics?.setProperties({
      language,
      companyId,
      userId,
      isShipper: isShipper.toString(),
      isCarrier: isCarrier.toString(),
      company_id: companyId,
      user_id: userId,
      is_shipper: isShipper.toString(),
      is_carrier: isCarrier.toString(),
      role_in_company: role,
      isTest: isTest.toString(),
    });
  }, [analytics, language, companyId, userId, role, isCarrier, isShipper, isTest]);

  React.useEffect(() => {
    const onResize = () => {
      analytics?.setProperties({
        window_width: window.innerWidth.toString(),
        window_height: window.innerHeight.toString(),
      });
    };

    onResize();
    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [analytics]);

  // We don't render any DOM
  return null;
};

export const IntercomAnalyticsPropsReporter: React.FunctionComponent<Props> = ({ company }) => {
  const isOnTransporeon = useIsPresentOnTransporeonPlatform(company.company_id);
  const user = useUserProfile(company.company_id);
  const analytics = useAnalytics();

  React.useEffect(() => {
    analytics?.setIntercomProperties({
      email: user?.email ?? undefined,
      name: [user?.first_name, user?.last_name].filter(notNil).join(' '),
      company: {
        companyId: company.company_id,
        name: company.company_name ?? undefined,
        customAttributes: {
          is_shipper: company.is_shipper,
          is_carrier: company.is_carrier,
          is_test: company.is_test,
        },
      },
      customAttributes: {
        first_name: user?.first_name,
        last_name: user?.last_name,
        locale: user?.locale,
        role_in_company: user?.company?.role,
        is_shipper: company.is_shipper,
        is_carrier: company.is_carrier,
        is_test: company.is_test,
        latest_company_id: company.company_id,
        latest_company_name: company.company_name,
        ...{
          ...(isOnTransporeon.loading
            ? {}
            : { latest_company_is_on_transporeon: isOnTransporeon.isPresentOnTransporeonPlatform }),
        },
      },
    });
  }, [
    analytics,
    user,
    company.company_id,
    company.company_name,
    company.is_carrier,
    company.is_shipper,
    isOnTransporeon,
    company.is_test,
  ]);

  // We don't render any DOM
  return null;
};

export const AnalyticsProvider: React.FunctionComponent<React.PropsWithChildren<{ consented: boolean }>> = ({
  consented,
  children,
}) => {
  const { embedConfig } = React.useContext(EmbedDataContext);
  const profile = useUserProfile();

  const routes = Object.values(Routes);
  const userId = profile?.user_id;
  const mixpanelToken = embedConfig?.mixpanel_token;
  const mixpanelProxyUrl = embedConfig?.mixpanel_proxy_url;
  const inspectletWid = embedConfig?.inspectlet_wid !== undefined ? Number(embedConfig.inspectlet_wid) : undefined;
  const intercomOptions: React.ComponentProps<typeof LibraryAnalyticsProvider>['intercomOptions'] =
    React.useMemo(() => {
      return {
        appId: embedConfig?.intercom_appid,
        shouldTrackCustomEvents: false,
      };
    }, [embedConfig]);

  return (
    <LibraryAnalyticsProvider
      userId={userId}
      mixpanelToken={mixpanelToken}
      mixpanelProxyUrl={mixpanelProxyUrl}
      inspectletWid={inspectletWid}
      intercomOptions={intercomOptions}
      routes={routes}
      isTrackingAllowed={consented}>
      {children}
    </LibraryAnalyticsProvider>
  );
};
