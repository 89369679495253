import { notNil } from '@sixfold/typed-primitives';

import { Company, CompanyPermissions, UserProfile } from '../entities';

export const isCarrier: (company: Company) => boolean = (company) => !company.is_shipper && company.is_carrier;
export const isShipper: (company: Company) => boolean = (company) => company.is_shipper;

export const getCompanyPermissions: (company: {
  is_shipper: boolean | null;
  is_carrier: boolean | null;
  is_test: boolean | null;
  arePlacesEnabled: boolean | null;
  isOceanUIEnabled: boolean;
  isAirUIEnabled: boolean;
  isInvitationFlowDisabled: boolean;
  shouldSeeUpcomingTransportsTab: boolean;
  isMapVehicleLocationsEnabled: boolean;
  isGlobalAllocationEnabled: boolean;
}) => CompanyPermissions = (company) => {
  const isShipper = company.is_shipper === true;
  const isCarrier = company.is_carrier === true;
  const placesEnabled = company.arePlacesEnabled === true;
  const invitationFlowEnabled = company.isInvitationFlowDisabled === false;

  return {
    shouldSeeCarrierPerformance: isShipper,
    shouldSeeIntegrations: isCarrier,
    shouldSeeVehicles: isCarrier,
    shouldSeePlaces: placesEnabled,
    shouldSeeFleetMonitor: isCarrier,
    shouldSeeOceanUI: company.isOceanUIEnabled,
    shouldSeeAirUI: company.isAirUIEnabled,
    shouldSeeInvitationFlow: invitationFlowEnabled,
    shouldSeeUpcomingTransportsTab: company.shouldSeeUpcomingTransportsTab,
    shouldSeeMapVehicleLocations: company.isMapVehicleLocationsEnabled,
    isGlobalAllocationEnabled: company.isGlobalAllocationEnabled,
  };
};

export function getUserName(profile: UserProfile) {
  const { first_name, last_name, email } = profile;
  return [first_name, last_name].filter(notNil).join(' ') || email;
}
